import { Motion } from "@capacitor/motion";
// import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useCallback, useEffect, useState } from "react";
import ReactMapGL, {
  FlyToInterpolator,
  FullscreenControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import { FaMapMarkerAlt } from "react-icons/fa";

// import { EulerAngles } from "../../Common/rotaionHelper";
// import { EulerAngles } from "../RotationHelper";
// import { AuthContext } from "../../context";

import PolylineOverlay from "../Polyline";
// import PolylineOverlay from "./Polyline";
import { AppLauncher } from "@capacitor/app-launcher";
const mapBoxToken =
  "pk.eyJ1IjoiZ2V0bG9vayIsImEiOiJjbDZjZ3J5a2wwNGhxM2ltYnozdXg5amRjIn0.2wlsfZYHoL9w2Not_l_l3g";

const defaultLat = 12.9716;
const defaultLng = 77.5946;

const AddressMap = ({ appointmentLocation, stylistLocation }) => {
  // const { location: agentLocation, setLocation: setAgentLocation } =
  //   useContext(AuthContext);
  const [agentLocation, setAgentLocation] = useState();
  const [viewport, setViewport] = React.useState({
    latitude: Number(appointmentLocation?.lat) || defaultLat,
    longitude: Number(appointmentLocation?.long) || defaultLng,
    zoom: 15,
  });

  // console.log("userAddress ---------------> ", appointmentLocation);
  // const [orientation, setOrientation] = useState(null);
  const [route, setRoute] = useState(null);

  const [userLocation, setUserLocation] = React.useState({});

  // open google maps with direction
  const openGoogleMapsWithDirection = async () => {
    // const { value } = await AppLauncher?.canOpenUrl({
    //   url: "com.google.android.apps.maps",
    // });
    // if (value)
    //   await AppLauncher?.openUrl({
    //     url: "com.getcapacitor.myapp://page?id=portfolio",
    //   });
    const url =
      "https://www.google.com/maps/dir/?api=1&origin=" +
      stylistLocation?.lat +
      "," +
      stylistLocation?.long +
      "&destination=" +
      appointmentLocation?.lat +
      "," +
      appointmentLocation?.long;
    // window.open(url, '_blank').focus();
    //   console.log('this is Url -----------------> ' , url);
    await AppLauncher?.openUrl({ url });
  };

  //track orientation
  const trackOrientation = async () => {
    Motion.addListener("orientation", (o) => {
      // const { alpha, beta, gamma } = o;
      // const angle = new EulerAngles(alpha, -90 + beta, gamma);
      // if (angle) setOrientation(angle);
    });
  };

  React.useEffect(() => {
    trackOrientation();
  }, []);

  // getting the coords of the path
  const getRoute = useCallback(
    async (data) => {
      if (!appointmentLocation || !data) return;
      fetch(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${appointmentLocation.long},${appointmentLocation.lat};${data.lng},${data.lat}?geometries=geojson&access_token=${mapBoxToken}`
      ).then((res) => {
        res.json().then((data) => {
          if (data.routes) setRoute(data.routes[0]?.geometry.coordinates);
        });
      });

      // const res = await axios.get(
      //   `https://api.mapbox.com/directions/v5/mapbox/driving/${userAddress.lng},${userAddress.lat};${data.lng},${data.lat}?geometries=geojson&access_token=${mapBoxToken}`
      // );

      // if (res) setRoute(res.data.routes[0].geometry.coordinates);
    },
    [appointmentLocation]
  );

  const flyToMyLocation = useCallback((pos) => {
    if (!pos) return;
    setViewport({
      ...viewport,
      longitude: pos.lng,
      latitude: pos.lat,
      zoom: 15,
      transitionDuration: 5000,
      transitionInterpolator: new FlyToInterpolator(),
    });
  }, []);

  useEffect(() => {
    if (!agentLocation) return;

    // updateLocation(agentLocation);
    flyToMyLocation(agentLocation);
    getRoute(agentLocation);
  }, [agentLocation, flyToMyLocation, getRoute]);

  // setting the customer location here
  React.useEffect(() => {
    if (!appointmentLocation) return;
    setUserLocation({
      lng: Number(appointmentLocation?.long) || defaultLng,
      lat: Number(appointmentLocation?.lat) || defaultLat,
    });
  }, [appointmentLocation]);
  React.useEffect(() => {
    if (!appointmentLocation) return;
    setAgentLocation({
      lng: Number(stylistLocation?.long) || defaultLng,
      lat: Number(stylistLocation?.lat) || defaultLat,
    });
  }, [stylistLocation, appointmentLocation]);

  // console.log("this is agent Address -------------------> ", agentLocation);

  if (!appointmentLocation) return <p>Address details of user not available</p>;
  return (
    <>
      <ReactMapGL
        {...viewport}
        mapboxApiAccessToken={mapBoxToken}
        height="80vh"
        width="100%"
        mapStyle="mapbox://styles/mapbox/streets-v8"
        onViewportChange={(viewport) => setViewport(viewport)}
      >
        {route && <PolylineOverlay points={route} />}
        {route && (
          <Marker latitude={userLocation.lat} longitude={userLocation.lng}>
            <FaMapMarkerAlt className="text-primary h3" />
          </Marker>
        )}
        <div style={{ right: 10, top: 10, position: "absolute" }}>
          <FullscreenControl onClick={() => openGoogleMapsWithDirection()} />
        </div>
        <div style={{ left: 10, top: 10, position: "absolute" }}>
          <NavigationControl />
        </div>
        <div style={{ left: 10, bottom: 10, position: "absolute", zIndex: 2 }}>
          <button
            onClick={() => openGoogleMapsWithDirection()}
            className=" btn btn-primary mx-auto d-block p-2 btn-sm"
          >
            Open in Google Maps
          </button>
        </div>
      </ReactMapGL>
      {/* <div className=" mt-2 ">
        <button
          onClick={() => openGoogleMapsWithDirection()}
          className=" btn btn-primary mx-auto d-block p-2"
        >
          Open in Google Maps
        </button>
      </div> */}
    </>
  );
};

export default AddressMap;
