import React, { useCallback, useEffect, useState } from "react";
import ClientSideMap from "./components/ClientSideMap/index";
import classes from "./index.module.css";

const App = () => {
  const [appLocation, setAppLocation] = useState({ lat: 0, long: 0 });
  const [stylLocation, setStylLocation] = useState({ lat: 0, long: 0 });
  const [stylistETA, setStylistETA] = useState("");

  function findGetParameter(parameterName) {
    var result = null,
      tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  }

  // let { id } = useParams();
  let id = findGetParameter("id");

  const fetchLocation = useCallback(async () => {
    await fetch("https://getlook.in/stylistlivetracking/v1/api", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        appId: id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Stylist data --> ", data);
        setAppLocation({
          lat: data?.appLat,
          long: data?.appLong,
        });
        setStylLocation({
          lat: data?.stylistLive[0].latitude,
          long: data?.stylistLive[0].longitude,
        });
        setStylistETA(data?.time);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    fetchLocation();
    const id = setInterval(() => fetchLocation(), 30 * 1000);
    return () => clearInterval(id);
  }, [fetchLocation]);

  return (
    <div>
      <div className={classes.ETA}>
        Your stylist will arrive in {stylistETA}
      </div>
      <ClientSideMap
        stylistLocation={stylLocation}
        appointmentLocation={appLocation}
      />
    </div>
  );
};

export default App;
